import React from 'react';
import './styles.scss';
import CurrencyMapper from 'qs-helpers/currency';
export const priceFormatter = (price, currency = 'INR') => {
  if (currency === 'GOG') {
    return (
      <p className="currency-gm">
        {Number(price).toFixed(2)} <span>{CurrencyMapper[currency]}</span>
      </p>
    );
  }
  return (currency ? CurrencyMapper[currency] : '') + Number(price).toFixed(0);
};

export default function PriceInfo({ name, price, quantity, big, currency }) {
  const finalPrice = (price || 0) * (quantity || 0);
  return (
    <div className="product-info-container">
      <div className="product-name">{name}</div>
      {!big && (
        <div className="product-price-breakdrown">
          {priceFormatter(price, currency)} x {quantity}
        </div>
      )}
      {!big && <div className="product-price">{priceFormatter(finalPrice, currency)}</div>}
    </div>
  );
}
